import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from 'ui-icon';
import { DialogComponent } from './dialog.component';

@NgModule({
	declarations: [DialogComponent],
	exports: [DialogComponent],
	imports: [CommonModule, UiIconModule],
})
export class UiDialogModule {}
